.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* remove quotation marks from blockquote */
  blockquote {
    quotes: none;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  ul[data-type="taskList"] {
    list-style-type: none;

    li {
      input[type="checkbox"] {
        border-radius: 100%;
        cursor: pointer;
      }
    }

    li[data-checked="true"] input[type="checkbox"] {
      background-color: #1cb7be;
    }
  }

  /* TODO: move to custom tailwind class plugin */
  /* see: https://github.com/tailwindlabs/tailwindcss/discussions/12496 */

  p code::before {
    content: "";
  }

  p code::after {
    content: "";
  }

  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    color: #888;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}